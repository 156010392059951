@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&family=Quicksand:wght@300;400&display=swap');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    background-color: #f8fff9;
    font-family: 'Roboto' !important;
}

.carousel .thumb {
    transition: border .15s ease-in;
    display: inline-block;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid #fff;
    padding: 2px;
    height: 80px;
}

@layer utilities {
    .pause {
        animation-play-state: paused;
    }
}

.slick-prev {
    color: brown;
    left: 3% !important;
    z-index: 1;
}

.slick-next {
    right: 3% !important;
    z-index: 1;
}

h3 {
    font-family: 'Merriweather' !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    color: #2129a3 !important;
}