.slider-content {
    padding: 0 70px;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.image-gallery-slides {
    @screen sm {
        height: 200px // your desired fix-height
    }
    @screen md {
        height: 500px // your desired fix-height
    }
}

@keyframes upwardFolding {
    100% {
        transform: matrix3d(1, 0, 0, 0, 0, 0.34202, 0.939693, -0.00156615, 0, -0.939693, 0.34202, -0.000570034, 0, -46.9846, -32.899, 1.05483);
        opacity: 0;
    }
    50% {
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        opacity: 1;
    }
    0% {
        transform: matrix3d(1, 0, 0.00, 0, 0.00, 1, 0.00, 0, 0, 0, 1, 0, 0, 30, 0, 1);
        opacity: 1;
    }
}

.animate-upwardFolding {
    animation: upwardFolding 4.1s infinite;
    visibility: visible;
}